body {
  font-family: 'conduit', -apple-system, BlinkMacSystemFont im !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'conduit';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04px;
  text-transform: none;
  text-decoration: none;
  color: black;
  line-height: 17;
  src: local('conduit'),
    url(./assets/fonts/conduit_itc_bold.ttf) format('opentype');
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: black !important;
}
